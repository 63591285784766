import { FC } from 'react';
import { Link } from 'react-router-dom';
import Card from 'src/components/Card';
import listFormsData from 'src/screens/request/listForms.json';
import { FormBuilderData } from 'src/types/formBuilder';

const formIds: (string | number)[] = [16, 11, 13];

const listForms = (listFormsData as FormBuilderData[]).filter((form) =>
  formIds.includes(form.id)
);

const RequestListEmpty: FC = () => {
  return (
    <div className="space-y-10">
      <div>
        <p>You currently have no open requests. </p>

        <p>
          Get started by selecting a popular option below or{' '}
          <Link className="!underline" to="/requests/create">
            click here
          </Link>{' '}
          to view all request types.
        </p>
      </div>

      <div className="grid grid-flow-row gap-4 text-left sm:grid-cols-2 lg:grid-cols-3 xl:pt-6 2xl:px-24">
        {listForms.map(
          (request) =>
            !request.disabled && (
              <Card
                key={request.name}
                name={request.name}
                link={`/requests/create/${request.id}`}
                imageUrl={request.imageUrl}
                tags={request.tags}
                buttonName="Request"
                ribbon={
                  request.timeEstimated && `Starting ${request.timeEstimated}`
                }
              >
                {request.description}
              </Card>
            )
        )}
      </div>

      <div>
        <Link className="font-bold" to="/requests/create">
          View more
        </Link>
      </div>
    </div>
  );
};

export default RequestListEmpty;
