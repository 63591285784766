import { ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import Toast from 'src/components/Toast';
import ProjectTimeReportCard from 'src/components/ProjectTimeReportCard';
import SmsSignUpModal from 'src/components/SmsSignUpModal';
import InviteModal from 'src/components/InviteModal';
import ResetPasswordModal from 'src/components/ResetPasswordModal';
import { RootState } from 'src/store';
import { getPendingForms } from 'src/store/pendingForms/reducer';
import { getPendingInvites } from 'src/store/pendingInvites/reducer';
import { getContacts } from 'src/store/contacts/reducer';
import { getInbox } from 'src/store/inbox/reducer';
import ENVIRONMENT from 'src/utils/environments';
import { UserResponse } from 'src/types';
import Navbar from './Navbar';
import MenuLeft from './MenuLeft';

const version = process.env.REACT_APP_GIT_HASH
  ? process.env.REACT_APP_GIT_HASH.substring(0, 6)
  : null;

export default function Layout({ children }: { children?: ReactNode }) {
  const [resetModal, setResetModal] = useState(false);
  const [inviteModal, setInviteModal] = useState(false);

  const { pathname } = useLocation();

  const user: UserResponse = useSelector(
    (globalState: any) => globalState.auth.user
  );
  const pendingForms = useSelector(
    (globalState: RootState) => globalState.pendingForms
  );

  const breakpoints = useBreakpoint(true);

  const dispatch = useDispatch();

  const showToast =
    !pathname.includes('pending') && !!pendingForms.data?.data?.length;

  const hidePendingNavItem = !pendingForms.data?.data?.length;

  const usagePage = pathname === '/usage';

  useEffect(() => {
    dispatch(getPendingForms());
    dispatch(getInbox());
  }, [dispatch]);

  return (
    <div className="relative flex min-h-[100vh] flex-col">
      <ResetPasswordModal
        visible={resetModal}
        onClose={() => setResetModal(false)}
      />

      <InviteModal
        visible={inviteModal}
        onInviteSent={() => {
          dispatch(getContacts());
          dispatch(getPendingInvites());
        }}
        onClose={() => setInviteModal(false)}
      />

      {user.preferences.sms_notifications === null ? (
        <SmsSignUpModal user={user} />
      ) : undefined}

      <Navbar
        hidePendingNavItem={hidePendingNavItem}
        onInvite={() => setInviteModal(true)}
        onResetPassword={() => setResetModal(true)}
      />

      <div className="mx-auto w-full max-w-[1920px] flex-1 px-2 pt-2 sm:px-6 sm:pt-4 xl:pt-0">
        <div className="flex flex-wrap gap-4 xl:gap-6">
          {breakpoints.xl ? (
            <div className="relative w-[280px]">
              <div
                className={`sticky top-0 flex flex-col justify-between ${
                  ENVIRONMENT.IS_PRODUCTION
                    ? `top-16 ${
                        showToast
                          ? 'min-h-[calc(100vh-140px)]'
                          : 'min-h-[calc(100vh-75px)]'
                      }`
                    : `top-[92px] ${
                        showToast
                          ? 'min-h-[calc(100vh-170px)]'
                          : 'min-h-[calc(100vh-105px)]'
                      }`
                }`}
              >
                <div>
                  <MenuLeft hidePendingItem={hidePendingNavItem} />

                  <div className="w-full p-2 pt-4">
                    <ProjectTimeReportCard
                      showTooltip
                      hideSeeDetail={usagePage}
                    />
                  </div>
                </div>

                <div
                  className={`hidden pt-2 text-xs text-gray-300 xl:block ${
                    showToast ? '-mb-16' : ''
                  }`}
                >
                  {version ? `Ver: ${version}` : undefined}
                </div>
              </div>
            </div>
          ) : (
            !usagePage && (
              <div className="w-full">
                <ProjectTimeReportCard showTooltip hideSeeDetail={usagePage} />
              </div>
            )
          )}

          <div className="w-full flex-1 basis-full overflow-hidden pb-6 xl:order-none xl:basis-0 xl:pb-16 xl:pt-4">
            {children}
          </div>

          {/*<div className="order-1 flex-1 flex-wrap items-start justify-between gap-6 xl:order-none xl:min-w-[160px] xl:max-w-[210px] xl:flex-none xl:flex-col"></div>*/}
        </div>
      </div>

      <div className="absolute bottom-1 left-6 text-xs text-gray-300 xl:hidden">
        {version ? `Ver: ${version}` : undefined}
      </div>

      {showToast && <Toast />}
    </div>
  );
}
