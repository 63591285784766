import { ConfigProvider } from 'antd';
import { FC, ReactNode } from 'react';

interface AntdConfigPublicProps {
  children: ReactNode;
}

const AntdConfigPublic: FC<AntdConfigPublicProps> = ({ children }) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            borderRadius: 12,
            borderRadiusSM: 12,
            borderRadiusLG: 12,
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};

export default AntdConfigPublic;
