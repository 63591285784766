import { cloneElement, CSSProperties, FC, ReactElement } from 'react';
import { feedbackIntegration } from '@sentry/react';
import { Badge, Breakpoint, Button, Dropdown, MenuProps, theme } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  TbBook,
  TbBug,
  TbBulb,
  TbFileInfo,
  TbMessage2Star,
  TbPhone,
  TbUserPlus,
} from 'react-icons/tb';
import {
  MdOutlineAddTask,
  MdOutlineDashboard,
  MdOutlineEmail,
  MdOutlineFolderCopy,
} from 'react-icons/md';
import { GrDocumentTime } from 'react-icons/gr';
import { BsClipboardData } from 'react-icons/bs';
import { IoCloseOutline, IoMenuOutline } from 'react-icons/io5';
import { FiFileText, FiUnlock, FiUsers } from 'react-icons/fi';
import { PiWarningOctagon } from 'react-icons/pi';
import { IoIosLogOut } from 'react-icons/io';
import GlobalSearch from 'src/components/GlobalSearch';
import CompanySelector from 'src/components/CompanySelector';
import UserAvatar from 'src/components/UserAvatar';
import { RootState } from 'src/store';
import { logout } from 'src/store/auth/reducer';
import ENVIRONMENT from 'src/utils/environments';
import { UserResponse } from 'src/types';

const iconClassName = 'mr-2 inline-block align-text-bottom text-2xl sm:text-lg';

const { useToken } = theme;

const sentryFeedback = feedbackIntegration({
  colorScheme: 'system',
  autoInject: false,
});

const sentryWidget = sentryFeedback.createWidget();

sentryWidget.el.style.display = 'none';

interface NavbarMenuProps {
  breakpoints: Partial<Record<Breakpoint, boolean>>;
  open: boolean;
  hidePendingItem: boolean;
  onOpenChange: (open: boolean) => void;
  onInvite: () => void;
  onResetPassword: () => void;
  onStartTour: () => void;
  setStepTarget: (step: number, target: HTMLElement | null) => void;
}

const NavbarMenu: FC<NavbarMenuProps> = ({
  breakpoints,
  open,
  hidePendingItem,
  onOpenChange,
  onInvite,
  onResetPassword,
  onStartTour,
  setStepTarget,
}) => {
  const user: UserResponse = useSelector(
    (globalState: any) => globalState.auth.user
  );
  const inboxUnread = useSelector(
    (globalState: RootState) => globalState.inbox.count
  );
  const customer = user.contact?.customer;
  const { data: project } = useSelector(
    (globalState: RootState) => globalState.projectRetainer
  );

  const dispatch = useDispatch();

  const logoutHandler = () => {
    dispatch({ type: 'store/reset' });
    dispatch(logout());
  };

  const menuItems: MenuProps['items'] = [
    // {
    //   key: 'profile',
    //   label: (
    //     <Link to="/profile">
    //       <HiOutlineUserCircle className={iconClassName} />
    //       Profile
    //     </Link>
    //   ),
    // },
    ...(!breakpoints.xl
      ? [
          {
            key: 'dashboard',
            label: (
              <Link to="/dashboard">
                <MdOutlineDashboard className={iconClassName} />
                Dashboard
              </Link>
            ),
          },
          ...(!hidePendingItem
            ? [
                {
                  key: 'pending',
                  className: 'menu-item-bg-danger',
                  label: (
                    <Link to="/pending">
                      <PiWarningOctagon
                        className={iconClassName}
                        strokeWidth="2"
                      />
                      Pending
                    </Link>
                  ),
                },
              ]
            : []),
          {
            key: 'inbox',
            label: (
              <Link to="/inbox" className="flex items-center">
                <MdOutlineEmail className={iconClassName} />
                <span className="flex-auto">My inbox</span>
                <Badge className="!ml-2" count={inboxUnread ?? 0} />
              </Link>
            ),
          },
          {
            key: 'create-request',
            label: (
              <Link to="/requests/create" preventScrollReset={true}>
                <MdOutlineAddTask className={iconClassName} />
                Create new request
              </Link>
            ),
          },
          ...(project
            ? [
                {
                  key: 'usage',
                  label: (
                    <Link to="/usage">
                      <GrDocumentTime className={iconClassName} />
                      Usage
                    </Link>
                  ),
                },
              ]
            : []),
          {
            key: 'contacts',
            label: (
              <Link to="/contacts">
                <FiUsers className={iconClassName} />
                Team members
              </Link>
            ),
          },
          ...(customer?.dashboard_link_1 || customer?.dashboard_link_2
            ? [
                {
                  key: 'report',
                  label: (
                    <Link to="/report">
                      <BsClipboardData className={iconClassName} />
                      Reports
                    </Link>
                  ),
                },
              ]
            : []),
          {
            key: 'assets',
            label: (
              <Link to="/project-assets">
                <MdOutlineFolderCopy className={iconClassName} />
                My assets
              </Link>
            ),
          },
          { key: 'separator-1', className: 'menu-item-separator' },
        ]
      : []),
    {
      key: 'invite',
      onClick: () => onInvite(),
      label: (
        <span
          className="block w-full"
          ref={(node) => {
            if (node) {
              setTimeout(() => {
                setStepTarget(2, node);
              }, 400);
            }
          }}
        >
          <TbUserPlus className={iconClassName} />
          Invite a team member
        </span>
      ),
    },
    {
      key: 'client-resources',
      label: (
        <Link
          ref={(node) => {
            if (node) {
              setTimeout(() => {
                setStepTarget(3, node);
              }, 400);
            }
          }}
          to="/client-resources"
        >
          <TbFileInfo className={iconClassName} />
          Resources
        </Link>
      ),
    },
    {
      key: 'booking',
      label: (
        <Link
          ref={(node) => {
            if (node) {
              setTimeout(() => {
                setStepTarget(4, node);
              }, 400);
            }
          }}
          to="/booking"
        >
          <TbPhone className={iconClassName} />
          Book a call
        </Link>
      ),
    },
    {
      key: 'company-profile',
      label: (
        <Link to="/company/profile">
          <FiFileText className={iconClassName} />
          My account
        </Link>
      ),
    },
    ...(!ENVIRONMENT.IS_PRODUCTION
      ? [
          {
            key: 'tour',
            onClick: () => onStartTour(),
            label: (
              <>
                <TbBook className={iconClassName} />
                Start tour
              </>
            ),
          },
          {
            key: 'review',
            label: (
              <a
                href="https://savageglobalmarketing.typeform.com/to/syFEQMWH?typeform-source=admin.lionshare.app"
                target="_blank"
                rel="noreferrer"
              >
                <TbMessage2Star className={iconClassName} />
                Review lionshare
              </a>
            ),
          },
          {
            key: 'bug',
            onClick: () => sentryWidget.el.click(),
            label: (
              <>
                <TbBug className={iconClassName} />
                Report a bug
              </>
            ),
          },
          {
            key: 'sugesstion',
            label: (
              <a
                href="https://lionshare.canny.io/feature-request"
                target="_blank"
                rel="noreferrer"
              >
                <TbBulb className={iconClassName} />
                Submit a suggestion
              </a>
            ),
          },
        ]
      : []),
    {
      key: 'reset-password',
      onClick: () => onResetPassword(),
      label: (
        <span
          ref={(node) => {
            if (node) {
              setTimeout(() => {
                setStepTarget(5, node);
              }, 400);
            }
          }}
          className="block w-full"
        >
          <FiUnlock className={iconClassName} />
          Reset password
        </span>
      ),
    },
    { key: 'separator-2', className: 'menu-item-separator' },
    {
      key: 'logoff',
      onClick: logoutHandler,
      label: (
        <>
          <IoIosLogOut className={iconClassName} strokeWidth={5} />
          Log off
        </>
      ),
    },
  ];

  const { token } = useToken();

  const contentStyle: CSSProperties = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    maxHeight: ENVIRONMENT.IS_PRODUCTION
      ? 'calc(100% - 60px)'
      : 'calc(100% - 88px)',
  };

  const menuStyle: CSSProperties = {
    boxShadow: 'none',
  };

  return (
    <Dropdown
      open={open}
      placement="topRight"
      trigger={['click']}
      menu={{ items: menuItems }}
      arrow={{ pointAtCenter: true }}
      rootClassName="ant-dropdown-fullscreen-sm sm-ant-dropdown-text-xl max-sm:h-full"
      onOpenChange={(v) => onOpenChange(v)}
      destroyPopupOnHide
      autoAdjustOverflow
      dropdownRender={(menu) => (
        <div
          className="dropdown-scroll flex min-w-[90vw] flex-col overflow-hidden sm:min-w-[500px] md:min-w-[240px]"
          style={contentStyle}
        >
          <div className="px-4 pb-1 pt-2 text-2xl font-bold sm:text-base">
            Hi, {user.name}
          </div>

          {!breakpoints.md && (
            <div
              className="p-1 md:w-[500px]"
              ref={(node) => {
                if (node) {
                  setTimeout(() => {
                    setStepTarget(0, node);
                  }, 400);
                }
              }}
            >
              <GlobalSearch
                onLinkClick={() => {
                  onOpenChange(false);
                }}
              />
            </div>
          )}

          <div
            className="flex-1 overflow-y-auto"
            onClick={() => onOpenChange(false)}
          >
            {cloneElement(menu as ReactElement, {
              style: menuStyle,
              className:
                'max-h-[calc(100vh-200px)] md:max-h-[calc(100vh-110px)]',
            })}
          </div>

          {user?.contact?.customers?.length > 1 && !breakpoints.md && (
            <div className="px-1 py-1">
              <CompanySelector
                className="w-full"
                customerLabel="business_name"
              />
            </div>
          )}
        </div>
      )}
    >
      <Button type="link" style={{ padding: 0 }}>
        <div className="hidden text-white md:block">
          <UserAvatar user={user} size="large" />
        </div>

        {open ? (
          <IoCloseOutline className="h-7 w-7 text-white md:hidden" />
        ) : (
          <IoMenuOutline className="h-7 w-7 text-white md:hidden" />
        )}
      </Button>
    </Dropdown>
  );
};

export default NavbarMenu;
