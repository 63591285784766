import { Link } from 'react-router-dom';
import SectionOne from './SectionOne';
import SectionTwo from './SectionTwo';
import SectionThree from './SectionThree';

const Home = () => {
  return (
    <main>
      <SectionOne />

      <SectionTwo />

      <SectionThree />

      <footer className="container-lp flex items-center justify-between ">
        <Link
          to="/"
          className="font-zephyr text-3xl leading-none text-primary max-sm:hidden"
        >
          lionshare
        </Link>

        <div className="font-geist text-sm text-neutral-400 max-sm:w-full max-sm:text-center">
          <span>
            Copyright© {new Date().getFullYear()} Savage Global Marketing®
          </span>

          <span className="max-sm:block"> All Rights Reserved.</span>
        </div>
      </footer>
    </main>
  );
};

export default Home;
