import { FC } from 'react';
import { Button, Checkbox, Empty, Skeleton, Spin } from 'antd';
import { TbSubtask } from 'react-icons/tb';
import RequestItem from '../RequestItem';
import RequestListEmpty from './RequestListEmpty';

interface TaskProps {
  className?: string;
  data: any[] | null;
  loading?: boolean;
  hasLoadMore?: boolean;
  showCompletedValue?: boolean;
  onLoadMore?: () => void;
  onShowAll?: (showAll: boolean) => void;
}

const RequestCard: FC<TaskProps> = ({
  className = '',
  data,
  loading = false,
  hasLoadMore = false,
  showCompletedValue,
  onShowAll,
  onLoadMore,
}) => {
  return (
    <div className={`flex w-full flex-col gap-2 ${className}`}>
      <div className="flex items-center gap-2 text-blue-600">
        <div className="flex flex-auto items-center gap-2">
          <TbSubtask className="text-2xl" />

          <span className="pr-2 font-bold">Requests</span>
        </div>

        {onShowAll ? (
          <div className="flex items-center gap-2">
            {loading && data && <Spin size="small" />}

            {onShowAll && (
              <Checkbox
                checked={showCompletedValue}
                onChange={(event) => onShowAll(event.target.checked)}
              >
                <span className="text-blue-600">Show all</span>
              </Checkbox>
            )}
          </div>
        ) : undefined}
      </div>

      <div>
        {loading && !data ? (
          <Skeleton className="py-4" />
        ) : (
          <div className="space-y-3">
            {data?.map((request) => {
              return <RequestItem key={request.uuid} request={request} />;
            })}

            {hasLoadMore && (
              <div className="flex justify-center">
                <Button loading={loading} onClick={onLoadMore}>
                  Load more
                </Button>
              </div>
            )}

            {!data?.length && <Empty description={<RequestListEmpty />} />}
          </div>
        )}
      </div>
    </div>
  );
};

export default RequestCard;
