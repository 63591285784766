import { Button, DatePicker, Empty, Skeleton } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { FC, useEffect, useState } from 'react';
import { IoFolderOpenOutline } from 'react-icons/io5';
import { TbFileTypePdf } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import Box from 'src/components/Box';
import RequestItem from 'src/components/RequestItem';
import ProjectTimeReportCard from 'src/components/ProjectTimeReportCard';
import apiRoutes from 'src/utils/apiRoutes';
import apiRequests from 'src/utils/api';
import { RootState } from 'src/store';
import {
  clearRequestsData,
  getRequests,
  setRequestsFilters,
} from 'src/store/requests/reducer';
import './index.style.scss';

const { RangePicker } = DatePicker;

const UsagePage: FC = () => {
  const project = useSelector(
    (globalState: RootState) => globalState.projectRetainer.data
  );
  const {
    loading: requestsLoading,
    data: requests,
    filters,
  } = useSelector((globalState: RootState) => globalState.requests);

  const [downloadingPDF, setDownloadingPDF] = useState(false);

  const dispatch = useDispatch();

  const filterDates = requests?.period_filters ?? [];

  useEffect(() => {
    dispatch(getRequests());
  }, [dispatch]);

  return requestsLoading || requests ? (
    <>
      <div className="mb-2 flex flex-wrap justify-between gap-2 px-1 text-blue-600">
        <div className="font-bold">
          <IoFolderOpenOutline className="mr-2 inline-block text-2xl" />

          <span className="align-middle">Usage</span>
        </div>

        <div className="flex items-center gap-2">
          <Button
            type="link"
            style={{ fontSize: '26px' }}
            icon={<TbFileTypePdf />}
            disabled={(!!requests && !requests.data.length) || downloadingPDF}
            onClick={() => {
              setDownloadingPDF(true);

              const fileName =
                `${project?.customer?.business_name} - ` +
                `Usage Report - ` +
                `${
                  filters.completed_at_start && filters.completed_at_end
                    ? `${dayjs(filters.completed_at_start).format(
                        'YYYYMMDD'
                      )}-${dayjs(filters.completed_at_end).format('YYYYMMDD')}`
                    : ''
                }.pdf`;

              apiRequests
                .blob(`${apiRoutes.PROJECTS}/${project?.uuid}/usage-requests`, {
                  'sort[by]': 'projects_requests.completed_at',
                  'sort[direction]': 'desc',
                  format: 'pdf',
                  date_range:
                    filters.completed_at_start && filters.completed_at_end
                      ? [filters.completed_at_start, filters.completed_at_end]
                      : undefined,
                })
                .then((response) => {
                  const fileURL = URL.createObjectURL(response.data);
                  const downloadLink = document.createElement('a');
                  downloadLink.href = fileURL;
                  downloadLink.target = '_blank';
                  downloadLink.download = fileName;
                  downloadLink.click();
                  URL.revokeObjectURL(fileURL);
                })
                .finally(() => {
                  setDownloadingPDF(false);
                });
            }}
          />

          <RangePicker
            className="!mr-4"
            dropdownClassName="usage-range-picker--dropdown"
            value={[
              filters.completed_at_start
                ? dayjs(filters.completed_at_start)
                : null,
              filters.completed_at_end ? dayjs(filters.completed_at_end) : null,
            ]}
            format="MM/DD/YYYY"
            presets={[
              {
                label: 'Last 30 days',
                value: [dayjs().subtract(30, 'd'), dayjs()],
              },
              {
                label: 'This month',
                value: [dayjs().startOf('month'), dayjs().endOf('month')],
              },
              {
                label: 'Last month',
                value: [
                  dayjs().subtract(1, 'month').startOf('month'),
                  dayjs().subtract(1, 'month').endOf('month'),
                ],
              },
              {
                label: 'Last 90 days',
                value: [dayjs().subtract(90, 'days').startOf('day'), dayjs()],
              },
              {
                label: 'All',
                // @ts-ignore
                value: null,
              },
              ...(filterDates && filterDates.length > 0
                ? [
                    {
                      label: 'See refill by date:',
                      value: [dayjs(), dayjs()] as [Dayjs, Dayjs],
                    },
                  ]
                : []),
              ...filterDates.map((dates) => {
                const from = dayjs(dates.from);
                const to = dayjs(dates.to);
                return {
                  label: `${from.format('MM/DD/YYYY')} - ${to.format(
                    'MM/DD/YYYY'
                  )}`,
                  value: [from, to] as [Dayjs, Dayjs],
                };
              }),
            ]}
            disabledDate={(value) =>
              value.isBefore(dayjs().subtract(90, 'days').startOf('day'))
            }
            onChange={(dates) => {
              const newDates =
                dates?.[0] && dates?.[1]
                  ? [
                      dates[0].format('YYYY-MM-DD'),
                      dates[1].format('YYYY-MM-DD'),
                    ]
                  : null;

              dispatch(clearRequestsData());

              dispatch(
                setRequestsFilters(
                  newDates
                    ? {
                        completed_at_start: newDates[0],
                        completed_at_end: newDates[1],
                      }
                    : null
                )
              );
            }}
          />
        </div>
      </div>

      <div className="mb-4">
        <ProjectTimeReportCard showDescription hideAvailable hideSeeDetail />

        <div className="mt-2 text-center text-xs">
          The hours listed below reflect the time spent during the selected date
          range.
        </div>
      </div>

      <div>
        {requestsLoading && !requests ? (
          <Skeleton className="py-4" />
        ) : (
          <div className="space-y-3">
            {requests?.data?.map((request) => {
              return (
                <RequestItem
                  key={request.uuid}
                  request={request}
                  showSpentInfo
                />
              );
            })}

            {!requests?.data?.length ? <Empty /> : undefined}
          </div>
        )}
      </div>
    </>
  ) : (
    <Box>
      <Empty description={<span className="text-gray-400">No project</span>} />
    </Box>
  );
};

export default UsagePage;
