import { AxiosResponse } from 'axios';
import {
  call,
  put,
  select,
  StrictEffect,
  takeLatest,
} from 'redux-saga/effects';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';
import { sortRequestByStatus } from 'src/utils/arrayHelpers';
import {
  addDashboardRequestsData,
  getDashboardRequests,
  RequestsStateType,
  setDashboardRequestsData,
  setDashboardRequestsError,
  setDashboardRequestsLoading,
} from './reducer';
import { RootState } from '../index';

const { get } = apiRequests;

function* getDashboardRequestSagaListener({ payload }: any) {
  try {
    yield put(setDashboardRequestsLoading());

    const storeRequest: RequestsStateType = yield select(
      (globalStore: RootState) => globalStore.dashboardRequests
    );

    const res: AxiosResponse = yield call(get, apiRoutes.PROJECT_REQUEST, {
      'page[size]': storeRequest.pagination.size,
      'page[number]':
        payload.loadMore && storeRequest.pagination.hasMore
          ? storeRequest.pagination.page + 1
          : 1,
      'filters[projects_requests.status]': [
        'draft',
        'new',
        'waiting_approval',
        'needs_attention',
        'approved',
        'in_progress',
        ...(storeRequest.showCompleted ? ['completed', 'cancelled'] : []),
      ],
      ...(payload.params ?? {}),
    });

    const hasMore = res.data.meta.current_page < res.data.meta.last_page;

    const data = sortRequestByStatus(res.data.data);

    if (payload.loadMore) {
      yield put(
        addDashboardRequestsData({
          data,
          hasMore,
          page: res.data.meta.current_page,
        })
      );
    } else {
      yield put(setDashboardRequestsData({ data, hasMore }));
    }
  } catch (error) {
    yield put(setDashboardRequestsError(error));
    asyncErrorHandler(error);
  }
}

function* dashboardRequestsSaga(): Generator<StrictEffect> {
  yield takeLatest(getDashboardRequests.type, getDashboardRequestSagaListener);
}

export default dashboardRequestsSaga;
