import { ReactNode } from 'react';
import { Button } from 'antd';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from 'src/store';
import AntdConfigPublic from './AntdConfigPublic';
import './index.css';

interface LayoutPublicProps {
  children: ReactNode;
}

const LayoutPublic = ({ children }: LayoutPublicProps) => {
  const authToken = useSelector(
    (globalState: RootState) => globalState.auth.token
  );

  return (
    <AntdConfigPublic>
      <div className="bg-blue-100 font-roboto">
        <header className="absolute w-full">
          <nav
            className="mx-auto flex max-w-[1174px] items-center justify-between px-4 py-6 md:px-10"
            aria-label="Global"
          >
            <div className="flex lg:flex-1">
              <Link
                to="/"
                className="font-zephyr text-3xl leading-none text-primary"
              >
                lionshare
              </Link>
            </div>

            <Button type="primary" size="large">
              <Link
                to={authToken ? '/dashboard' : '/login'}
                className="font-medium"
              >
                {authToken ? 'Go to Dashboard' : 'Login'}
              </Link>
            </Button>
          </nav>
        </header>

        {children}
      </div>
    </AntdConfigPublic>
  );
};

export default LayoutPublic;
