import { FC, useRef } from 'react';
import { Tooltip } from 'antd';
import './index.style.scss';

interface ProgressBarDoubleProps {
  mainPercent: number;
  secondaryPercent: number;
  mainTooltip?: string;
  secondaryTooltip?: string;
  backgroundTooltip?: string;
  showDescription?: boolean;
  showTooltip?: boolean;
}

const ProgressBarDouble: FC<ProgressBarDoubleProps> = ({
  mainPercent,
  secondaryPercent,
  mainTooltip,
  secondaryTooltip,
  backgroundTooltip,
  showDescription,
  showTooltip,
}) => {
  const backgroundBarRef = useRef<HTMLDivElement>(null);
  const secondaryBarRef = useRef<HTMLDivElement>(null);
  const mainBarRef = useRef<HTMLDivElement>(null);

  const biggerPercent =
    mainPercent > secondaryPercent ? mainPercent : secondaryPercent;

  let backgroundPercent = 100 - biggerPercent;

  backgroundPercent = backgroundPercent < 0 ? 0 : backgroundPercent;

  backgroundPercent = backgroundPercent > 100 ? 100 : backgroundPercent;

  return (
    <div className="w-full">
      <div
        className={`progressbar-double${
          mainPercent > 100 ? ' progressbar-double-danger' : ''
        }${showDescription ? ' progressbar-description' : ''}`}
      >
        <Tooltip
          title={backgroundTooltip}
          placement="topLeft"
          open={showTooltip ? undefined : false}
        >
          <div
            ref={backgroundBarRef}
            className="progressbar-double__background-bar"
            style={{
              width: `calc(${backgroundPercent}% + 20px)`,
              left:
                biggerPercent < 3
                  ? undefined
                  : `calc(${biggerPercent}% - 20px)`,
            }}
          ></div>
        </Tooltip>

        <Tooltip
          title={secondaryTooltip}
          open={showTooltip ? undefined : false}
        >
          <div
            ref={secondaryBarRef}
            className={`progressbar-double__secondary-bar${
              secondaryPercent > 100
                ? ' progressbar-double__secondary-bar-danger'
                : ''
            }`}
            style={{
              width:
                mainPercent >= secondaryPercent
                  ? `${secondaryPercent}%`
                  : `calc(${secondaryPercent - mainPercent}% + 20px)`,
              left:
                mainPercent >= secondaryPercent || mainPercent < 3
                  ? undefined
                  : `calc(${mainPercent}% - 20px)`,
            }}
          ></div>
        </Tooltip>

        <Tooltip title={mainTooltip} open={showTooltip ? undefined : false}>
          <div
            ref={mainBarRef}
            className={`progressbar-double__main-bar${
              mainPercent > 100 ? ' progressbar-double__main-bar-danger' : ''
            }`}
            style={{ width: `${mainPercent}%` }}
          ></div>
        </Tooltip>
      </div>

      {showDescription && (
        <div className="mt-2 flex flex-wrap items-center justify-center gap-x-4 text-primary">
          <span
            className={
              mainPercent > 100
                ? 'progressbar-double__legend-danger-main'
                : 'progressbar-double__legend-main'
            }
          >
            {mainTooltip}
          </span>

          <span
            className={
              secondaryPercent > 100
                ? 'progressbar-double__legend-danger-secondary'
                : 'progressbar-double__legend-secondary'
            }
          >
            {secondaryTooltip}
          </span>

          <span
            className={`progressbar-double__legend-background${
              backgroundTooltip?.startsWith('-') ? ' text-red-500' : ''
            }`}
          >
            {backgroundTooltip}
          </span>
        </div>
      )}
    </div>
  );
};

export default ProgressBarDouble;
