import { createSlice } from '@reduxjs/toolkit';

export interface RequestsStateType {
  loading: boolean;
  data: Record<string, any>[] | null;
  error: null | string;
  showCompleted: boolean;
  pagination: {
    size: number;
    page: number;
    hasMore: boolean;
  };
}

const initialState: RequestsStateType = {
  loading: false,
  data: null,
  error: null,
  showCompleted: false,
  pagination: {
    size: 100,
    page: 1,
    hasMore: false,
  },
};

export const dashboardRequestsSlice = createSlice({
  name: 'dashboardRequests',
  initialState,
  reducers: {
    getDashboardRequests: (
      _,
      __: {
        payload: { params: Record<string, any>; loadMore?: boolean };
        type: string;
      }
    ) => {},
    setDashboardRequestsLoading: (state) => ({ ...state, loading: true }),
    addDashboardRequestsData: (
      state,
      action: {
        payload: { data: any[]; page: number; hasMore?: boolean };
        type: string;
      }
    ) => ({
      ...state,
      loading: false,
      data: [...(state.data ?? []), ...action.payload.data],
      pagination: {
        ...state.pagination,
        page: action.payload.page,
        hasMore: action.payload.hasMore ?? false,
      },
    }),
    setDashboardRequestsData: (state, action) => ({
      ...state,
      loading: false,
      data: action.payload.data,
      pagination: {
        ...state.pagination,
        page: 1,
        hasMore: action.payload.hasMore ?? false,
      },
    }),
    clearDashboardRequestsData: () => initialState,
    setDashboardRequestsError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    setDashboardRequestCompleted: (
      state,
      action: { payload: boolean; type: string }
    ) => ({
      ...state,
      showCompleted: action.payload,
    }),
  },
});

export const {
  setDashboardRequestsData,
  addDashboardRequestsData,
  setDashboardRequestsError,
  setDashboardRequestsLoading,
  getDashboardRequests,
  clearDashboardRequestsData,
  setDashboardRequestCompleted,
} = dashboardRequestsSlice.actions;

export default dashboardRequestsSlice.reducer;
