import { FC, ReactNode, useRef, useState } from 'react';
import { Button, Popconfirm, Row, Space, Tag, Typography } from 'antd';
import { AiOutlineDelete, AiOutlineFileImage } from 'react-icons/ai';
import { Comment } from '@ant-design/compatible';
import { UploadResponse, UserResponse } from 'src/types';
import {
  convertSnakeCaseToTitleCase,
  mentionCommentParser,
  parseDateTimeToUSFormat,
} from 'src/utils/stringHelpers';
import UserAvatar from '../UserAvatar';
import DownloadAsset from '../DownloadAsset';
import RichTextEditor, { RichTextEditorHandle } from '../RichTextEditor';
import CommentActions, {
  CommentActionsChildrenProps,
  CommentActionsParentProps,
} from '../CommentActions';

interface CommentMessageBaseProps {
  comment: any;
  user: UserResponse;
  projectUuid: string;
  targetCommentId: any;
  showCommentType?: boolean;
  mentionTypes?: ('regular_user' | 'freelancer' | 'contact' | 'group')[];
  onImgDelete: any;
  onReplyTo: CommentActionsParentProps['onReplyTo'];
  children?: ReactNode;
}

interface CommentMessageParentProps extends CommentMessageBaseProps {
  parentEl?: undefined;
  onAddReaction: CommentActionsParentProps['onAddReaction'];
  onRemoveReaction: CommentActionsParentProps['onRemoveReaction'];
  onUpdate: CommentActionsParentProps['onUpdate'];
  onDelete: CommentActionsParentProps['onDelete'];
}

interface CommentMessageChildrenProps extends CommentMessageBaseProps {
  parentEl: CommentActionsChildrenProps['parentEl'];
  onAddReaction: CommentActionsChildrenProps['onAddReaction'];
  onRemoveReaction: CommentActionsChildrenProps['onRemoveReaction'];
  onUpdate: CommentActionsChildrenProps['onUpdate'];
  onDelete: CommentActionsChildrenProps['onDelete'];
}

export type CommentMessageProps =
  | CommentMessageParentProps
  | CommentMessageChildrenProps;

const CommentMessage: FC<CommentMessageProps> = (props) => {
  const {
    comment,
    user,
    projectUuid,
    showCommentType,
    targetCommentId,
    mentionTypes,
    children,
    onImgDelete,
    onReplyTo,
  } = props;
  const [editing, setEditing] = useState(false);
  const [editingText, setEditingText] = useState('');
  const commentRef = useRef<RichTextEditorHandle>(null);

  const bgTargetComment =
    targetCommentId === comment.uuid ? '#e2e2e2' : undefined;

  let actions: JSX.Element | undefined;

  if (!comment.deleted_at) {
    actions = props.parentEl ? (
      <CommentActions
        key="actions"
        comment={comment}
        user={user}
        editing={editing}
        editingText={editingText}
        editorText={commentRef}
        parentEl={props.parentEl}
        onEdit={(edit) => {
          setEditing(edit);
          setEditingText(mentionCommentParser(comment.text));
        }}
        onReplyTo={onReplyTo}
        onAddReaction={props.onAddReaction}
        onRemoveReaction={props.onRemoveReaction}
        onUpdate={props.onUpdate}
        onDelete={props.onDelete}
      />
    ) : (
      <CommentActions
        key="actions"
        comment={comment}
        user={user}
        editing={editing}
        editingText={editingText}
        editorText={commentRef}
        onEdit={(edit) => {
          setEditing(edit);
          setEditingText(mentionCommentParser(comment.text));
        }}
        onReplyTo={onReplyTo}
        onAddReaction={props.onAddReaction}
        onRemoveReaction={props.onRemoveReaction}
        onUpdate={props.onUpdate}
        onDelete={props.onDelete}
      />
    );
  }

  return (
    <Comment
      style={{
        background: bgTargetComment ? '#e7e7e7' : undefined,
        borderRadius: 5,
        marginInlineEnd: props.parentEl ? '10px' : undefined,
      }}
      className={`comment-${comment.uuid}`}
      author={
        <>
          <Space style={{ minHeight: 22 }}>
            <p className="text-gray-600">{comment.creator?.name}</p>
            <p className="text-gray-400">
              {parseDateTimeToUSFormat(comment.created_at)}
            </p>

            {!props.parentEl && showCommentType && (
              <Tag color={comment.type === 'private' ? 'gold' : 'blue'}>
                {convertSnakeCaseToTitleCase(comment.type)}
              </Tag>
            )}
          </Space>

          {comment.creator?.job_title ? (
            <div className="mb-1 leading-none">
              {comment.creator?.job_title}
            </div>
          ) : undefined}
        </>
      }
      avatar={<UserAvatar user={comment.creator} />}
      content={
        <div>
          <RichTextEditor
            ref={commentRef}
            value={editing ? editingText : mentionCommentParser(comment.text)}
            className={`${editing ? '' : 'comment-styles'}${
              comment.deleted_at ? ' deleted-message' : ''
            }`}
            placeholder="Add a comment"
            projectId={projectUuid}
            includeToolbar={false}
            readonly={!editing}
            mentionTypes={mentionTypes}
            noContainer={!editing}
            onChange={setEditingText}
            includeMention
          />

          {comment.uploads?.length > 0 && !comment.deleted_at && (
            <Space size={10} direction="vertical" className="w-full">
              {comment.uploads?.map((file: UploadResponse) => {
                return (
                  <Row
                    style={{
                      background: '#F0F0F0',
                      padding: 5,
                      borderRadius: 3,
                      paddingLeft: 20,
                    }}
                    align="middle"
                    justify="space-between"
                    key={file.uuid}
                  >
                    <Typography.Link
                      href={`/file-viewer/${file.uuid}`}
                      target="_blank"
                      className="flex items-center gap-2"
                    >
                      <AiOutlineFileImage />

                      <span>{file.name}</span>
                    </Typography.Link>

                    <Space size={1}>
                      <DownloadAsset uuid={file.uuid} name={file.name} />

                      {!comment.deleted_at &&
                        comment.creator.uuid === user.uuid && (
                          <Popconfirm
                            placement="bottom"
                            title="Do you want to delete this image?"
                            onConfirm={() =>
                              onImgDelete(file.uuid, comment.uuid)
                            }
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button
                              type="link"
                              icon={
                                <AiOutlineDelete className="text-red-500" />
                              }
                            />
                          </Popconfirm>
                        )}
                    </Space>
                  </Row>
                );
              })}
            </Space>
          )}
          {actions}
        </div>
      }
    >
      {children}
    </Comment>
  );
};

export default CommentMessage;
