import { Button, Modal } from 'antd';
import { FC, useState } from 'react';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import { FaPlay } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import lpOne from 'src/assets/images/lading-page-1.png';

const SectionOne: FC = () => {
  const [openVideo, setOpenVideo] = useState(false);

  const authToken = useSelector(
    (globalState: RootState) => globalState.auth.token
  );

  return (
    <section className="container-lp flex h-full min-h-[660px] flex-wrap items-center gap-6 !pb-10 !pt-24 md:gap-10 md:!pb-20 lg:gap-24">
      <Modal
        open={openVideo}
        className="video-modal"
        bodyStyle={{ aspectRatio: 1.92 }}
        width={960}
        footer={null}
        destroyOnClose
        onCancel={() => setOpenVideo(false)}
      >
        <ReactPlayer
          url="https://vimeo.com/1048918188"
          width="100%"
          height="100%"
          config={{ vimeo: { playerOptions: { autoplay: true } } }}
          controls
        />
      </Modal>

      <div className="flex-auto max-md:text-center md:flex-1">
        <h2 className="mb-5 font-geist text-4xl font-semibold leading-tight tracking-tight text-primary lg:text-6xl">
          Your All-in-One Marketing Tool
        </h2>

        <article className="mb-8 text-neutral-500">
          Lionshare is an all-in-one solution that connects you with our team
          from any device, providing a request system, real-time notifications,
          time tracking, and more.
        </article>

        <Button type="primary" size="large">
          <Link
            to={authToken ? '/dashboard' : '/login'}
            className="font-medium"
          >
            {authToken ? 'Go to Dashboard' : 'Login'}
          </Link>
        </Button>
      </div>

      <div className="relative max-w-md flex-1 max-md:mx-auto md:max-w-xs lg:max-w-full">
        <button
          className="block"
          type="button"
          onClick={() => setOpenVideo(true)}
        >
          <img src={lpOne} alt="landing-page-1" className="max-md:px-6" />

          <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center">
            <div className="rounded-full bg-blue-100 p-3">
              <FaPlay className="ml-0.5" />
            </div>
          </div>
        </button>
      </div>
    </section>
  );
};

export default SectionOne;
