import { FC } from 'react';
import lpTwo from 'src/assets/images/lading-page-2.png';
import {
  TbAugmentedReality,
  TbCarouselHorizontal,
  TbClockBolt,
  TbDeviceMobileHeart,
} from 'react-icons/tb';

const SectionTwo: FC = () => {
  return (
    <section className="bg-white pt-8 font-geist md:pt-16">
      <div className="container-lp">
        <div className="flex flex-wrap gap-y-4 p-4 lg:p-10">
          <div className="order-1 flex flex-1 flex-col gap-y-4 max-md:basis-full md:py-4 lg:py-16">
            <div className="flex-1">
              <div className="mb-2 flex items-center gap-3 font-semibold leading-tight text-primary">
                <TbCarouselHorizontal className="inline-block rounded bg-primary py-1 text-2xl leading-none text-white" />
                Easy Navigation
              </div>

              <div className="text-neutral-400">
                Our dashboard and menu are intuitive—no instructions needed.
              </div>
            </div>

            <div className="flex flex-1 flex-col justify-end">
              <div className="mb-2 flex items-center gap-3 font-semibold leading-tight text-primary">
                <TbClockBolt className="inline-block rounded bg-primary py-1 text-2xl leading-none text-white" />
                Make Requests In Seconds
              </div>

              <div className="text-neutral-400">
                A system that simplifies requests with quick, hassle-free
                multiple-choice options.
              </div>
            </div>
          </div>

          <div className="relative order-3 flex flex-1 items-center px-[25%] max-md:mt-6 max-md:basis-full sm:px-[18%] max-md:sm:mx-20 md:order-2 md:px-[12.5%]">
            <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center">
              <div className="aspect-square w-full rounded-full bg-blue-50"></div>
            </div>

            <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center">
              <div className="aspect-square w-[77%] rounded-full bg-blue-100"></div>
            </div>

            <img
              src={lpTwo}
              alt="landing-page-2"
              className="relative mx-auto"
            />
          </div>

          <div className="order-2 flex flex-1 flex-col gap-y-4 max-md:basis-full md:order-3 md:py-4 lg:py-16">
            <div className="flex-1">
              <div className="mb-2 flex items-center gap-3 font-semibold leading-tight text-primary">
                <TbDeviceMobileHeart className="inline-block rounded bg-primary py-1 text-2xl leading-none text-white" />
                Mobile Frendly
              </div>

              <div className="text-neutral-400">
                Optimized design for effortless mobile browsing and user
                experience.
              </div>
            </div>

            <div className="flex flex-1 flex-col justify-end">
              <div className="mb-2 flex items-center gap-3 font-semibold leading-tight text-primary">
                <TbAugmentedReality className="inline-block rounded bg-primary py-1 text-2xl leading-none text-white" />
                Everything In One Place
              </div>

              <div className="text-neutral-400">
                Easily follow and manage your requests.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionTwo;
