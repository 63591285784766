import { FC } from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { SlGlobe } from 'react-icons/sl';
import { TbSparkles } from 'react-icons/tb';
import lpThree from 'src/assets/images/lading-page-3.png';

const SectionThree: FC = () => {
  return (
    <section className="bg-white pb-6 md:pb-12">
      <div className="container-lp">
        <div className="flex flex-wrap items-center gap-8 p-4 md:gap-12 lg:gap-24 lg:p-10">
          <div className="flex-1 max-md:mx-auto max-md:max-w-sm max-md:px-6">
            <div className="relative">
              <div className="absolute bottom-0 left-0 h-full w-full pr-10 pt-10">
                <div className="h-full w-full rounded-2xl bg-blue-100" />
              </div>

              <img
                src={lpThree}
                alt="landing-page-2"
                className="relative pb-10 pl-10"
              />

              <div className="absolute -right-10 top-2 flex items-center gap-2.5 rounded-full border border-neutral-300 bg-white px-4 py-1.5 text-xs font-semibold text-primary-dark lg:-right-24 lg:top-6 lg:px-7 lg:py-2 lg:text-sm">
                <SlGlobe className="text-base lg:text-lg" />
                Login from anywhere
              </div>

              <div className="absolute -left-10 bottom-12 flex items-center gap-2.5 rounded-full border border-neutral-300 bg-white px-4 py-1.5 text-xs font-semibold text-primary-dark lg:-left-16 lg:bottom-16 lg:px-7 lg:py-2 lg:text-sm">
                <TbSparkles className="text-base lg:text-lg" />
                Control in the palm of your hand
              </div>
            </div>
          </div>

          <div className="space-y-5 max-md:text-center md:flex-1">
            <h2 className="font-geist text-4xl font-semibold leading-tight tracking-tight text-primary">
              Take full control of your business marketing decisions
            </h2>

            <article className="font-geist text-neutral-400">
              Experience limitless possibilities with Lionshare—manage requests,
              follow-ups, feedback, and decisions from anywhere.
            </article>

            <Button type="primary" size="large">
              <Link to="/dashboard" className="font-medium">
                Access Now!
              </Link>
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionThree;
