import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

interface RequestFilters {
  completed_at_start?: string;
  completed_at_end?: string;
}

interface UsageRequestApi {
  data: Record<string, any>[];
  period_filters: { from: string; to: string }[];
}

const initialState = {
  loading: false,
  data: null as UsageRequestApi | null,
  filters: {
    completed_at_start: dayjs().subtract(30, 'd').format('YYYY-MM-DD'),
    completed_at_end: dayjs().format('YYYY-MM-DD'),
  } as RequestFilters,
  error: null,
};

export const requestsSlice = createSlice({
  name: 'requests',
  initialState,
  reducers: {
    getRequests: () => {},
    setRequestsLoading: (state) => ({ ...state, loading: true }),
    setRequestsData: (state, action) => ({
      ...state,
      loading: false,
      data: action.payload,
    }),
    setRequestsFilters: (
      state,
      { payload }: { payload: RequestFilters | null; type: string }
    ) => ({
      ...state,
      filters: payload ? { ...state.filters, ...payload } : {},
    }),
    clearRequestsData: (state) => ({
      ...state,
      data: null,
      loading: false,
      error: null,
    }),
    setRequestsError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
  },
});

export const {
  getRequests,
  setRequestsData,
  setRequestsError,
  setRequestsLoading,
  setRequestsFilters,
  clearRequestsData,
} = requestsSlice.actions;

export default requestsSlice.reducer;
