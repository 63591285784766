import { AxiosResponse } from 'axios';
import {
  call,
  put,
  select,
  StrictEffect,
  takeLatest,
} from 'redux-saga/effects';
import {
  getRequests,
  setRequestsData,
  setRequestsError,
  setRequestsFilters,
  setRequestsLoading,
} from './reducer';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';
import { RootState } from '../index';

const { get } = apiRequests;

function* getRequestsSagaListener(): any {
  const { filters }: RootState['requests'] = yield select(
    (state) => state.requests
  );
  const project: any = yield select((state) => state.projectRetainer.data);

  if (!project) {
    return;
  }

  try {
    yield put(setRequestsLoading());

    const res: AxiosResponse = yield call(
      get,
      `${apiRoutes.PROJECTS}/${project.uuid}/usage-requests`,
      {
        'sort[by]': 'projects_requests.completed_at',
        'sort[direction]': 'desc',
        date_range:
          filters.completed_at_start && filters.completed_at_end
            ? [filters.completed_at_start, filters.completed_at_end]
            : undefined,
      }
    );

    yield put(setRequestsData(res.data));
  } catch (error) {
    yield put(setRequestsError(error));
  }
}

function* requestsSaga(): Generator<StrictEffect> {
  yield takeLatest(getRequests.type, getRequestsSagaListener);
  yield takeLatest(setRequestsFilters.type, getRequestsSagaListener);
}

export default requestsSaga;
