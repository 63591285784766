import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { FaRegCopy } from 'react-icons/fa';
import { Tag, Tooltip, Typography } from 'antd';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import {
  convertMinToHrsMin,
  getRequestStatusLabel,
  getStatusColor,
  parseDateToMMMDD,
} from 'src/utils/stringHelpers';
import RequestIcon from 'src/screens/request/RequestIcon';
import { parseFormBuilder } from 'src/screens/request/RequestViewPage';

const { Text } = Typography;

export interface RequestItemProps {
  request: Record<string, any>;
  showSpentInfo?: boolean;
}

const RequestItem = ({ request, showSpentInfo }: RequestItemProps) => {
  const isCompleted = request.status === 'completed';

  const { lg } = useBreakpoint();

  const clientName = `by ${request.requester?.name ?? 'No user'}`;

  const formBuilder = useMemo(() => parseFormBuilder(request), [request]);

  return (
    <Link
      to={`/requests/${request.uuid}`}
      className="box-content flex flex-wrap items-center gap-x-3 rounded-md bg-white p-3 hover:bg-[#0000000f] lg:flex-nowrap"
      preventScrollReset
    >
      <div className="flex w-full items-center gap-x-3 overflow-hidden leading-none max-lg:mb-3 lg:w-auto lg:flex-auto">
        <div className="rounded bg-blue-50 p-2 text-2xl leading-none">
          <RequestIcon formId={formBuilder.id} />
        </div>

        <div>
          <div className="text-xs text-gray-400">{request.title}</div>

          <Text className="font-bold" ellipsis>
            {lg ? (
              request.subject
            ) : (
              <Tooltip title={request.subject}>{request.subject}</Tooltip>
            )}
          </Text>
        </div>
      </div>

      <div className="flex w-full flex-none flex-wrap items-center gap-x-2 sm:flex-nowrap lg:w-auto">
        <div className="w-full flex-none overflow-hidden leading-none sm:w-auto sm:flex-auto lg:w-[200px] lg:flex-none">
          <Text className="!text-xs !text-gray-400" ellipsis>
            {clientName}
          </Text>

          <div className="text-sm">
            {isCompleted
              ? request.completed_at
                ? `Completed: ${parseDateToMMMDD(request.completed_at)}`
                : 'No completed date'
              : request.target_date
              ? `Delivery: ${parseDateToMMMDD(request.target_date, true)}`
              : 'No due date'}
          </div>
        </div>

        {!showSpentInfo && (
          <div className="w-[160px] flex-auto sm:flex-none">
            <div className="text-xs text-gray-400">
              {isCompleted ? 'Spent' : 'Estimated'}
            </div>

            <div className="text-sm">
              {isCompleted
                ? `${convertMinToHrsMin(request.time_spent)} h`
                : request.estimated_min || request.estimated_max
                ? `${convertMinToHrsMin(
                    request.estimated_min
                  )} - ${convertMinToHrsMin(request.estimated_max)} h`
                : 'No estimated time'}
            </div>
          </div>
        )}

        {showSpentInfo && (
          <div className="w-[160px] flex-auto sm:flex-none">
            <div className="text-xs text-gray-400">Time spent</div>

            <div className="text-sm">
              {convertMinToHrsMin(request.spent_in_period)} h
            </div>
          </div>
        )}

        <div className="flex w-[130px] flex-none items-center justify-end gap-2">
          <Tag color={getStatusColor(request.status)} style={{ margin: 0 }}>
            {getRequestStatusLabel(request.status)}
          </Tag>

          <Link
            to={`/requests/${request.uuid}/duplicate`}
            className="inline-block text-sm text-[#0044CC]"
          >
            <FaRegCopy />
          </Link>
        </div>
      </div>
    </Link>
  );
};

export default RequestItem;
