import React, { ReactNode } from 'react';
import { TbExternalLink } from 'react-icons/tb';
import { Link } from 'react-router-dom';
import { Tag } from 'antd';

interface CardProps {
  name: string;
  category?: string;
  children?: ReactNode;
  link: string;
  imageUrl: string;
  ribbon?: string;
  buttonName?: string;
  tags?: string[];
  typeButton?: 'default' | 'outline';
  externalLink?: boolean;
}

const linkClassName = 'block rounded-md px-3.5 py-2.5 text-center text-sm ';

const linkClassNameType: Record<'default' | 'outline', string> = {
  default: '!bg-primary !text-white',
  outline:
    'border border-primary hover:bg-indigo-50 focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-primary',
};

const Card: React.FC<CardProps> = ({
  name,
  category,
  children,
  link,
  imageUrl,
  ribbon,
  buttonName = 'Learn more',
  tags,
  typeButton = 'default',
  externalLink,
}) => {
  return (
    <div className="mb-4 flex h-full flex-col rounded-md bg-white p-4">
      <div className="relative flex-none">
        {ribbon && (
          <div className="absolute left-0 top-3 rounded-r bg-[#7482E3] px-3 py-1 text-xs text-white">
            {ribbon}
          </div>
        )}

        <img
          className="w-full rounded border border-indigo-100 sm:max-h-[300px]"
          src={imageUrl}
          alt={`${name}'s banner`}
        />
      </div>

      <div className="flex flex-1 flex-col justify-between gap-3 pt-4">
        <div>
          <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
            {name}
          </h3>

          {category && (
            <p className="text-sm leading-6 text-gray-400">{category}</p>
          )}

          {children && <p className="mb-3 text-sm text-gray-700">{children}</p>}

          {tags?.map((tag) => (
            <Tag
              key={tag}
              bordered={false}
              style={{ background: 'rgba(0, 68, 204, 0.1)' }}
            >
              {tag}
            </Tag>
          ))}
        </div>

        {externalLink ? (
          <a
            href={link}
            rel="noreferrer"
            target="_blank"
            className={linkClassName}
          >
            {buttonName}{' '}
            <TbExternalLink
              className="-mt-1 inline h-4 w-4 text-primary"
              aria-hidden="true"
            />
          </a>
        ) : (
          <Link
            to={link}
            className={`${linkClassName} ${linkClassNameType[typeButton]}`}
          >
            {buttonName}
          </Link>
        )}
      </div>
    </div>
  );
};

export default Card;
